<template>
  <v-card elevation="0" flat :loading="loading">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>Proposer une heure</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form class="px-3">
        <v-row>
          <v-col cols="8">
            <v-menu max-width="100%" v-model="dateDebutMenu" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field :rules="[notNullNorEmpty]"  v-on="on" v-bind="attrs" label="Date du début"
                              :value="getFormattedDate(date_debut)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker @click="date_fin = date_debut" v-model="date_debut"></v-date-picker>
                <v-card-actions>
                  <v-btn text color="primary" @click="dateDebutMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu max-width="100%" v-model="heureDebutMenu" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field :rules="[notNullNorEmpty]" v-on="on" v-bind="attrs" label="Heure du début"
                              :value="heure_debut"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_debut"></v-time-picker>
                <v-card-actions>
                  <v-btn text color="primary" @click="heureDebutMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-menu v-model="dateFinMenu" max-width="100%" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date du fin"
                              :value="getFormattedDate(date_fin)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_fin"></v-date-picker>
                 <v-card-actions>
                  <v-btn text color="primary" @click="dateFinMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="heureFinMenu" max-width="100%" :close-on-click="true" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure de fin"
                              :value="heure_fin"></v-text-field>
              </template>
              <v-card>
              <v-time-picker format="24hr" v-model="heure_fin"></v-time-picker>
                <v-card-actions>
                  <v-btn text color="primary" @click="heureFinMenu = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="proposeHour">
        Proposer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import dayjs from "dayjs";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";

export default {
  name: 'HourPropositionForm',
  mixins: [DateUtilMixin, ValidationRulesMixin],
  data() {
    return {
      "date_debut": null,
      "heure_debut": null,
      "date_fin": null,
      "heure_fin": null,
      "dateDebutMenu": false,
      "heureDebutMenu": false,
      "dateFinMenu": false,
      "heureFinMenu": false,
      "loading":false,
    }
  },
  computed: {
    selected_intervention() {
      return this.$store.getters["interventions/getSelectedIntervention"];
    }
  },
  methods: {
    async proposeHour() {
      this.loading = true;
      let ticketId = this.selected_intervention.ticket.id;
      let intervention = {
        "id": this.selected_intervention.id,
        "date_intervention": this.getCleanDate(this.date_debut, this.heure_debut),
        "date_fin_intervention": this.getCleanDate(this.date_fin, this.heure_fin),
        "date_reponse": dayjs().toISOString(),
      }
      await this.$store.dispatch("interventions/patchIntervention", intervention);
      await this.$store.dispatch("tickets/patchTicket", {"id": ticketId,"state": "ready"});
      await this.$store.dispatch("interventions/getInterventionById", intervention.id)
      this.loading = false;
      this.$store.dispatch("drawer/closeDialog");
    },

  }
}
</script>