<template>
    <v-card elevation="0" flat :loading="loading">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title>Modifier l'intervention</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card-text>
      <v-form class="px-3">
        <v-row>
          <v-col cols="8">
            <v-menu v-model="menu_date_debut" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field :rules="[notNullNorEmpty]" v-on="on" v-bind="attrs" label="Date du début"
                              :value="getFormattedDate(date_debut)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_debut" @change="date_fin = date_debut"></v-date-picker>
                <v-card-actions>
                  <v-btn @click="menu_date_debut = false" text color="primary">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="menu_heure_debut" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure du début"
                              :value="heure_debut"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_debut"></v-time-picker>
                <v-card-actions>
                  <v-btn @click="menu_heure_debut = false" text color="primary">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-menu v-model="menu_date_fin" max-width="100%" :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Date du fin"
                              :value="getFormattedDate(date_fin)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_fin"></v-date-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_date_fin = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="menu_heure_fin" :close-on-content-click="false">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure de fin"
                              :value="heure_fin"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_fin"></v-time-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_heure_fin = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-menu v-model="menu_date_realise" max-width="290" class="ma-0 pa-0" :close-on-content-click="false" :close-on-click="true">>
              <template v-slot:activator="{on, attrs}">
                <v-text-field persistent-hint hint="Assigner une date de réalisation à une intervention non réalisée changera le statut du ticket."
                              v-on="on" v-bind="attrs" label="Date de réalisation"
                              :value="getFormattedDate(date_realise)"></v-text-field>
              </template>
              <v-card>
                <v-date-picker v-model="date_realise"></v-date-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_date_realise = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="4">
            <v-menu v-model="menu_heure_realise" max-width="290" class="ma-0 pa-0" :close-on-content-click="false" :close-on-click="true">
              <template v-slot:activator="{on, attrs}">
                <v-text-field v-on="on" v-bind="attrs" label="Heure de réalisation"
                              :value="heure_realise"></v-text-field>
              </template>
              <v-card>
                <v-time-picker format="24hr" v-model="heure_realise"></v-time-picker>
                <v-card-actions>
                  <v-btn color="primary" text @click="menu_heure_realise = false">Fermer</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox label="Validation prestataire" v-model="validation"></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="editHour">
        Modifier
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";
import InterventionMixin from "@/mixins/interventions/InterventionMixin";
import dayjs from "dayjs";

export default {
  name: 'InterventionEditionForm',
  mixins: [DateUtilMixin, AuthMixin, ValidationRulesMixin, InterventionMixin],
  data() {
    let intervention = this.$store.getters["interventions/getSelectedIntervention"];
    return {
      "date_debut": intervention?.date_intervention !== null ? dayjs(intervention?.date_intervention).format("YYYY-MM-DD") : null,
      "heure_debut": intervention?.date_intervention !== null ? dayjs(intervention?.date_intervention).format("HH:mm") : null,
      "date_fin": intervention?.date_fin_intervention !== null ? dayjs(intervention?.date_fin_intervention).format("YYYY-MM-DD") : null,
      "heure_fin": intervention?.date_fin_intervention !== null ? dayjs(intervention?.date_fin_intervention).format("HH:mm") : null,
      "date_realise": intervention?.date_realisation !== null ? dayjs(intervention?.date_realisation).format("YYYY-MM-DD") : null,
      "heure_realise": intervention?.date_realisation !== null ? dayjs(intervention?.date_realisation).format("HH:mm") : null,
      "validation": intervention?.validation !== null ? intervention?.validation : false,
      "loading": false,
      "menu_date_debut": false,
      "menu_heure_debut": false,
      "menu_date_fin": false,
      "menu_heure_fin": false,
      "menu_date_realise": false,
      "menu_heure_realise": false,
    };
  },
  computed: {
    selected_intervention() {
      return this.$store.getters["interventions/getSelectedIntervention"];
    },
  },
  methods: {
    async editHour() {
      this.loading = true;
      let intervention = {
        "id": this.selected_intervention.id,
        "date_intervention": this.getCleanDate(this.date_debut, this.heure_debut),
        "date_fin_intervention": this.getCleanDate(this.date_fin, this.heure_fin),
        "date_realisation": this.getCleanDate(this.date_realise, this.heure_realise),
        "validation": this.validation,
      }
      await this.$store.dispatch("interventions/patchIntervention", intervention);
      await this.$store.dispatch("messages/announceSuccess", "Intervention modifié.")
      this.loading = false;
      this.$store.dispatch('drawer/closeDialog');
    }
  }
}
</script>